<template>
  <div class="test-details-page px-2" ref="test-details-page">
    <v-row justify="center">
      <v-col md="6">
        <div v-if="testDetails">
          <v-list-item class="px-0">
            <v-list-item-avatar>
              <v-img :src="testDetails.created_by.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                testDetails.created_by.full_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                testDetails.created_by.username
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card class="py-1">
            <v-card-title class="py-0">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ testDetails.tname }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap"
                  v-html="TextHelper.linkify(testDetails.description)"
                  >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-title>

            <v-list-item>
              <v-list-item-icon
                ><v-icon>mdi-book-open-variant</v-icon></v-list-item-icon
              >

              <v-list-item-content>
                <v-list-item-subtitle>Subject</v-list-item-subtitle>
                <v-list-item-title
                  >{{ testDetails.subject.name }} |
                  {{ testDetails.subject.room.sections }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon
                ><v-icon>mdi-format-list-checks</v-icon></v-list-item-icon
              >

              <v-list-item-content>
                <v-list-item-subtitle>Total marks</v-list-item-subtitle>
                <v-list-item-title
                  >{{ testDetails.marks }} Marks</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon
                ><v-icon>mdi-clock-start</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-subtitle>Starts at</v-list-item-subtitle>
                <v-list-item-title>{{
                  moment(testDetails.startsAt).format("lll")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon
                ><v-icon>mdi-clock-end</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-subtitle>Ends at</v-list-item-subtitle>
                <v-list-item-title>{{
                  moment(testDetails.endsAt).format("lll")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div class="px-2 mb-2">
              <div v-if="canManage">
                <v-btn
                  v-if="isTestUpcoming"
                  color="primary"
                  block
                  @click="Helper.openUrl(editTestUrl)"
                  >Edit Questions</v-btn
                >
                <v-btn
                  v-else-if="isTestOngoing"
                  color="primary"
                  block
                  @click="Helper.openUrl(viewTestStatsForFacultyUrl)"
                  >Test Ongoing - View Stats</v-btn
                >
                <v-btn
                  v-else-if="isTestEnded"
                  color="primary"
                  block
                  @click="Helper.openUrl(viewTestStatsForFacultyUrl)"
                  >Test Ended - View Stats</v-btn
                >
              </div>
              <div v-else-if="currentUser.is_student">
                <v-btn
                  v-if="isTestUpcoming"
                  color="primary"
                  block
                  disabled
                  @click="Helper.openUrl(editTestUrl)"
                  >Starts {{ moment(testDetails.startsAt).fromNow() }}</v-btn
                >
                <v-btn
                  v-else-if="isTestOngoing"
                  color="primary"
                  block
                  @click="Helper.openUrl(takeTestUrl)"
                  >Take Test</v-btn
                >
                <v-btn
                  v-else-if="isTestEnded"
                  color="primary"
                  block
                  @click="Helper.openUrl(studentTestResultUrl)"
                  >Test has ended. View results!</v-btn
                >
                <!-- TODO: show results here... -->
              </div>
            </div>
          </v-card>
          <v-card class="my-2" v-if="canManage">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Show Results?</v-list-item-title
                >
                <v-list-item-subtitle class="text-wrap"
                  >If enabled, results in the <b>Results</b> section will be
                  visible to students who have appeared for the
                  test</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action class="non-focused">
                <v-checkbox
                  blur
                  class="non-focused"
                  v-model="testDetails.showResult"
                  @click="showResultsConfirmationDialog = true"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </div>
        <div v-else-if="isLoading">
          <v-skeleton-loader
            class="my-2 mx-2"
            type="list-item-avatar"
          ></v-skeleton-loader>
          <v-skeleton-loader
            elevation="1"
            class="my-2 mx-2"
            type="list-item-three-line, card"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <!-- edit test -->
    <intract-create-edit-sheet
      persistent
      title="Edit Online Test"
      :visible="onlineTestEditSheet.visible"
      @close="
        onlineTestEditSheet.visible = false;
        onlineTestEditSheet.editId = null;
      "
      :fields="formFields"
      submit-button-text="Edit"
      :endpoint="endpoints.onlineTestViewSet"
      :edit-id="onlineTestEditSheet.editId"
      :edit-success-message="onlineTestEditSheet.editSuccessMessage"
      :data-object="onlineTestEditSheet.obj"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (onlineTestEditSheet.obj = obj)"
    />
    <!-- show results confirmation dialog -->
    <confirmation-dialog
      :title="
        testDetails && !testDetails.showResult
          ? 'Are you sure you want to hide the results from the students?'
          : 'Are you sure you want to show the results to the students?'
      "
      :visible="showResultsConfirmationDialog"
      @successCallback="toggleShowResults"
      @failureCallback="
        showResultsConfirmationDialog = false;
        testDetails.showResult = !testDetails.showResult;
      "
    />
    <!-- DELETE CONFIRMATION DIALOG -->
    <confirmation-dialog
      title="Are you sure you want to delete this Online Test?"
      description="This action cannot be undone"
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteOnlineTest"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>
<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import moment from "moment";
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import countdown from "countdown";
export default {
  name: "TestDetails",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractCreateEditSheet,
  },
  data() {
    return {
      TextHelper,
      moment,
      countdown,
      testDetails: null,
      showDeleteDialog: false,
      showResultsConfirmationDialog: false,
      selectedRoom: null,
      updateTimerId: 1,
      onlineTestEditSheet: {
        visible: false,
        editSuccessMessage: "Online Test edited successfully!",
        editId: null,
        obj: {
          module: this.$route.params.moduleId,
          faculty: null,
        },
      },
    };
  },
  computed: {
    appBarOptions() {
      if (!this.testDetails) return null;
      if (this.currentUser.is_student) return null;
      if (
        !this.currentUser.is_admin &&
        this.currentUser.id == this.testDetails.created_by.id
      )
        return null;
      return {
        actions: [
          {
            id: 1,
            title: "Edit Test",
            icon: "mdi-pencil",
            onClick: () => {
              this.onlineTestEditSheet.editId = this.testDetails.id;
              this.onlineTestEditSheet.visible = true;
            },
          },
          {
            id: 2,
            title: "Delete Test",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      };
    },

    canManage() {
      return (
        this.testDetails &&
        (this.currentUser.is_admin ||
          this.currentUser.id == this.testDetails.created_by.id)
      );
    },

    editTestUrl() {
      return `${this.endpoints.baseTestsUrl}test/edit/${this.$route.params.testId}`;
    },

    viewTestStatsForFacultyUrl() {
      return `${this.endpoints.baseTestsUrl}test/${this.$route.params.testId}/stats`;
    },

    takeTestUrl() {
      return `${this.endpoints.baseTestsUrl}test/${this.$route.params.testId}`;
    },
    studentTestResultUrl() {
      return `${this.endpoints.baseTestsUrl}test/${this.$route.params.testId}/stats/student/${this.currentUser.id}`;
    },

    subjectsEndpoint() {
      if (!this.testDetails || this.currentUser.is_student) return null;
      if (this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.testDetails.subject.room.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },
    formFields() {
      return {
        tname: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 6,
          max: 255,
        },
        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          endpoint: this.subjectsEndpoint,
          items: [],
          itemText: "name",
          itemSubtitle: "room.sections",
          itemValue: "id",
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          required: false,
          max: 3000,
        },
        startsAt: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.onlineTestEditSheet.obj.endsAt ||
              moment(this.onlineTestEditSheet.obj.endsAt).isAfter(
                moment(this.onlineTestEditSheet.obj.startsAt)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.onlineTestEditSheet.obj.startsAt).isAfter(moment()) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        endsAt: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.onlineTestEditSheet.obj.startsAt == null,
          min: new Date(this.onlineTestEditSheet.obj.startsAt),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.onlineTestEditSheet.obj.endsAt).isAfter(
                moment(this.onlineTestEditSheet.obj.startsAt)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
          // valid: () => moment(this.onlineTestEditSheet.obj.endsAt).isAfter(this.onlineTestEditSheet.obj.startsAt)
        },
      };
    },

    isTestUpcoming() {
      if (this.testDetails && this.updateTimerId) {
        return moment(this.testDetails.startsAt).isAfter(moment());
      }
      return false;
    },

    isTestOngoing() {
      if (this.testDetails && this.updateTimerId) {
        return (
          moment(this.testDetails.startsAt).isBefore(moment()) &&
          moment(this.testDetails.endsAt).isAfter(moment())
        );
      }
      return false;
    },

    isTestEnded() {
      if (this.testDetails && this.updateTimerId) {
        return moment(this.testDetails.endsAt).isBefore(moment());
      }
      return false;
    },
  },
  methods: {
    async getTestDetails() {
      var url =
        this.endpoints.onlineTestViewSet + this.$route.params.testId + "/";
      this.testDetails = await this.api.call(this.essentials, url);
      EventBus.$emit("online_tests__test_updated", this.testDetails);
      this.startCountDownTimerIfTestUpcoming();
    },

    async startCountDownTimerIfTestUpcoming() {
      var self = this;
      if (!this.testDetails) return;
      if (moment(this.testDetails.startsAt).isSameOrAfter(moment())) {
        //only start timer if  test is upcoming
        setInterval(() => {
          self.updateTimerId++;
        }, 1000); // used to force recomputation of computed time functions
      }
    },

    async toggleShowResults() {
      this.showResultsConfirmationDialog = false;
      var url =
        this.endpoints.onlineTestViewSet + this.$route.params.testId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { showResult: this.testDetails.showResult }
      );
      if (response) {
        this.showSnackbar({
          title: response.showResult
            ? "Results are now being shown!"
            : "Results successfully hidden",
          type: "success",
        });
      }
    },

    async deleteOnlineTest() {
      var url = this.endpoints.onlineTestViewSet + this.testDetails.id + "/";
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Online Test has been successfully deleted!",
          type: "success",
        });
        EventBus.$emit(
          "online_tests__test_deleted",
          Number(this.$route.params.testId)
        );

        this.$router.back();
      }
    },

    async onRefresh() {
      this.getTestDetails();
    },
  },
  async created() {
    await this.getTestDetails();
    console.log(countdown(new Date(), this.testDetails.endsAt).toString());
    if (this.currentUser.is_admin || this.currentUser.is_faculty) {
      this.onlineTestEditSheet.obj.created_by = this.currentUser.id;
    }
  },
};
</script>